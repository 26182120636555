import { Address } from '@medusajs/medusa';
import { Modal } from 'antd';
import React, { useState } from 'react';

import { useCustomer } from '../../../hooks/use-customer';
import notification from '../../utility/notification';

const AddressSetDefaultModal = ({
  selectedAddress,
  resetAction,
}: {
  selectedAddress: Address | null;
  resetAction: () => void;
}) => {
  const {
    actions: { setDefaultShippingAddress, me },
  } = useCustomer();

  const [loading, setLoading] = useState(false);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      selectedAddress && (await setDefaultShippingAddress(selectedAddress));

      await me();

      notification({
        key: 'set-default-address-success',
        type: 'success',
        message: 'ตั้งที่อยู่จัดส่งหลักสำเร็จ',
      });

      resetAction();
    } catch (error) {
      notification({
        key: 'set-default-address-error',
        type: 'error',
        message: 'พบข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={!!selectedAddress}
      title="ยืนยันการตั้งค่าเป็นที่อยู่จัดส่งหลัก"
      okText="ยืนยัน"
      cancelText="ยกเลิก"
      confirmLoading={loading}
      onOk={handleUpdate}
      onCancel={() => resetAction()}
    >
      ยืนยันการตั้งค่าเป็นที่อยู่จัดส่งหลัก {selectedAddress?.first_name}{' '}
      {selectedAddress?.last_name} ของคุณ
    </Modal>
  );
};

export default AddressSetDefaultModal;
