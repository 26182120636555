import { Address } from '@medusajs/medusa';
import { Button, Tag } from 'antd';
import { sortBy } from 'lodash';
import React, { useReducer } from 'react';

import { formatAddressLine } from '../../../helpers/address.helper';
import useCountry from '../../../hooks/useCountry';
import AddressDeleteModal from './AddressDeleteModal';
import AddressSetDefaultModal from './AddressSetDefaultModal';
import EditAddressFormModal from './EditAddressFormModal';

type ActionState = {
  address: Address | null;
  action: 'edit' | 'delete' | 'setDefault' | null;
};

type ActionType =
  | {
      address: Address;
      type: 'edit' | 'delete' | 'setDefault' | 'reset';
    }
  | {
      type: 'reset';
    };

const actionReducer = (state: ActionState, action: ActionType): ActionState => {
  switch (action.type) {
    case 'edit': {
      return {
        address: action.address,
        action: 'edit',
      };
    }
    case 'delete': {
      return {
        address: action.address,
        action: 'delete',
      };
    }
    case 'setDefault': {
      return {
        address: action.address,
        action: 'setDefault',
      };
    }
    case 'reset': {
      return {
        address: null,
        action: null,
      };
    }
    default:
      return state;
  }
};

const AddressList = ({ addresses }: { addresses: Address[] }) => {
  const [state, dispatch] = useReducer(actionReducer, {
    address: null,
    action: null,
  });

  const resetAction = () => dispatch({ type: 'reset' });

  const sortedAddresses = sortBy(addresses, (address) =>
    address.metadata?.isDefault ? 0 : 1
  );

  return (
    <>
      <ul>
        {sortedAddresses.map((address) => (
          <AddressItem
            key={address.id}
            address={address}
            dispatchAction={dispatch}
          />
        ))}
      </ul>
      <EditAddressFormModal
        selectedAddress={state.action === 'edit' ? state.address : null}
        resetAction={resetAction}
      />
      <AddressSetDefaultModal
        selectedAddress={state.action === 'setDefault' ? state.address : null}
        resetAction={resetAction}
      />
      <AddressDeleteModal
        selectedAddress={state.action === 'delete' ? state.address : null}
        resetAction={resetAction}
      />
    </>
  );
};

const AddressItem = ({
  address,
  dispatchAction,
}: {
  address: Address;
  dispatchAction: React.Dispatch<ActionType>;
}) => {
  const { getOption } = useCountry();

  const addressLine = formatAddressLine(address);

  const country = address.country_code
    ? getOption(address.country_code)?.label
    : '';

  return (
    <li className="flex flex-col gap-y-1 border-b border-light-divider py-6 text-sm text-light-title first:pt-0 last:border-none last:pb-0 md:flex-row md:justify-between md:gap-x-3">
      <div className="md:flex-1 md:basis-72">
        <div className="font-semibold">
          <span className="mr-2">
            {address.first_name} {address.last_name}
          </span>
          {address.metadata?.isDefault ? (
            <Tag color="blue">ที่อยู่หลัก</Tag>
          ) : null}
        </div>
        <div>{address.company}</div>
        <div>
          {addressLine} <br />
          {address.province}, {address.postal_code}, {country}
        </div>
        <div>{address.phone}</div>
      </div>
      <div className="flex flex-wrap gap-2 pt-5 md:basis-72 md:justify-end md:self-end md:pt-0">
        <Button
          size="small"
          className="!text-primary-6"
          onClick={() => dispatchAction({ address, type: 'edit' })}
        >
          แก้ไข
        </Button>
        {!address.metadata?.isDefault ? (
          <>
            <Button
              size="small"
              className="!text-primary-6"
              onClick={() => dispatchAction({ address, type: 'delete' })}
            >
              ลบ
            </Button>
            <Button
              size="small"
              className="!text-primary-6"
              onClick={() => dispatchAction({ address, type: 'setDefault' })}
            >
              ตั้งเป็นที่อยู่หลัก
            </Button>
          </>
        ) : null}
      </div>
    </li>
  );
};

export default AddressList;
