import { Button } from 'antd';
import React, { useState } from 'react';

import { useCustomer } from '../../../hooks/use-customer';
import AccountMultipleMinusIcon from '../../../icons/account-multiple-minus';
import PlusIcon from '../../../icons/plus';
import { MAX_ADDRESS_LENGTH } from '../../../lib/address';
import Breadcrumb from '../../Breadcrumb';
import Section from '../../Section';
import MainLayout from '../../layout/MainLayout';
import Card, { CardBody, CardHeader } from '../../ui/Card';
import AccountLayout, {
  ADDRESS_PATH,
  BASE_ACCOUNT_PATH,
} from '../AccountLayout';
import AddressList from './AddressList';
import NewAddressFormModal from './NewAddressFormModal';

const AddressPage = () => {
  const { customer } = useCustomer();

  const addresses = customer?.shipping_addresses || [];

  return (
    <MainLayout>
      <Breadcrumb
        items={[
          {
            title: 'บัญชีของฉัน',
            link: BASE_ACCOUNT_PATH,
          },
          {
            title: 'ที่อยู่จัดส่ง',
            link: ADDRESS_PATH,
          },
        ]}
        title="ข้อมูลส่วนตัว"
      />
      <Section className="py-6">
        <AccountLayout>
          <Card>
            <CardHeader>
              <div className="flex w-full items-center justify-between">
                ที่อยู่จัดส่ง
                {addresses.length < MAX_ADDRESS_LENGTH && <NewAddressAction />}
              </div>
            </CardHeader>
            <CardBody>
              {addresses.length > 0 ? (
                <AddressList addresses={addresses} />
              ) : (
                <EmptyPlaceholder />
              )}
            </CardBody>
          </Card>
        </AccountLayout>
      </Section>
    </MainLayout>
  );
};

const NewAddressAction = () => {
  const [isNewAddressFormOpen, setIsNewAddressFormOpen] = useState(false);

  return (
    <>
      <Button
        icon={<PlusIcon />}
        className="!flex gap-x-1"
        type="primary"
        onClick={() => setIsNewAddressFormOpen(true)}
      >
        เพิ่มที่อยู่ใหม่
      </Button>
      <NewAddressFormModal
        open={isNewAddressFormOpen}
        setOpen={(open: boolean) => setIsNewAddressFormOpen(open)}
      />
    </>
  );
};

const EmptyPlaceholder = () => {
  return (
    <div className="flex h-44 flex-col items-center justify-center gap-y-4">
      <AccountMultipleMinusIcon />
      <div className="text-sm text-light-secondary">ที่อยู่ของคุณยังว่าง</div>
    </div>
  );
};

export default AddressPage;
