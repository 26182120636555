import { Address } from '@medusajs/medusa';
import { Modal } from 'antd';
import React, { useState } from 'react';

import { useCustomer } from '../../../hooks/use-customer';
import { useMedusa } from '../../../hooks/use-medusa';
import notification from '../../utility/notification';

const AddressDeleteModal = ({
  selectedAddress,
  resetAction,
}: {
  selectedAddress: Address | null;
  resetAction: () => void;
}) => {
  const {
    actions: { me },
  } = useCustomer();

  const client = useMedusa();

  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      selectedAddress &&
        (await client.customers.addresses.deleteAddress(selectedAddress.id));

      await me();

      notification({
        key: 'delete-address-success',
        type: 'success',
        message: 'ลบที่อยู่จัดส่งสำเร็จ',
      });

      resetAction();
    } catch (error) {
      notification({
        key: 'delete-address-error',
        type: 'error',
        message: 'พบข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={!!selectedAddress}
      title="ยืนยันการลบที่อยู่จัดส่ง"
      okButtonProps={{ danger: true }}
      okText="ยืนยัน"
      cancelText="ยกเลิก"
      confirmLoading={loading}
      onOk={handleDelete}
      onCancel={() => resetAction()}
    >
      กรุณายืนยันเพื่อลบทิ้งที่อยู่จัดส่ง {selectedAddress?.first_name}{' '}
      {selectedAddress?.last_name} ของคุณ
    </Modal>
  );
};

export default AddressDeleteModal;
